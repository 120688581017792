<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        Добавить свидетельство к ТС
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="white pa-5 rounded-0">
        <div class="rounded px-4 pb-3">
          <v-row>
            <v-col cols="3">
              <label>Номер свидетельства</label>
              <v-text-field
                disabled
                :value="item.doc_number"
                background-color="grey lighten-2"
                dense
                hide-details
                outlined
              />
            </v-col>
            <v-col cols="3">
              <label>Дата</label>
              <custom-date-picker
                disabled
                :value="item.doc_date"
                label="Дата документа"
                dense
                hide-details
                outlined
              />
            </v-col>
          </v-row>
        </div>
        <v-simple-table
          fixed-header
          height="500"
        >
          <template>
            <thead>
              <tr>
                <th />
                <th
                  v-for="header in headers"
                  :key="header.value"
                  scope="row"
                  class="text-left"
                >
                  {{ header.text }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in transports"
                :key="idx"
              >
                <td>
                  <v-checkbox
                    dense
                    color="teal lighten-2"
                    hide-details
                    :disabled="selectedTransport ? selectedTransport !== item.id : false"
                    @click="onCheckboxClick(item.id)"
                  />
                </td>
                <td
                  v-for="header in headers"
                  :key="`${idx}-${header.value}`"
                >
                  {{ item[header.value] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="d-flex justify-end mt-4">
          <v-btn
            :disabled="!selectedTransport"
            color="#5CB7B1"
            elevation="0"
            darks
            @click="submit"
          >
            Применить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>


import formatDate from '@/mixins/format-date.js'
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from "vuex";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";

export default {
  components: {CustomDatePicker},
  mixins: [modalWindowMixin, formatDate],
  props:{
    item:{
      type:Object,
      required:true,
    }
  },
  data: () => ({
    document:null,
    date:null,
    selectedTransport:null,
    headers: [
      {
        text:'Номер ТС',
        value:'number'
      },
      {
        text:'Номер шасси (VIN)',
        value:'vin'
      },
      {
        text:'Страна рег.',
        value:'country_letter'
      },
      {
        text:'Код вида ТС',
        value:'vehicle_type'
      },
      {
        text:'Код марки ТС',
        value:'transport_mark_code'
      },
      {
        text:'Номер документа',
        value:'doc_number'
      },
    ],
  }),
  computed:{
    ...mapGetters({
      shipmentIndex:'uvr/getShipmentIndex',
      selected:'uvr/getSelected',
      user:'auth/getUser'
    }),
    transports(){
      return this.selected.transports;
    },
    userId(){
      return this.user?.user?.id
    },
    divisionId() {
      return this.user?.user?.activeDivisionId;
    }
  },
  methods:{
    onCheckboxClick(id){
      this.selectedTransport = this.selectedTransport === id ? null : id
    },
    submit() {
      const transportItem = this.transports.find(i => i.id === this.selectedTransport)
      const payload = {
        id: transportItem.id,
        division_id: this.divisionId,
        number: transportItem.number,
        country_letter: transportItem.country_letter,
        country_name:transportItem.country_name,
        vehicle_type: transportItem.vehicle_type,
        transport_mark_name: transportItem.transport_mark_name,
        transport_mark_code:transportItem.transport_mark_code,
        vin:transportItem.vin,
        doc_number: transportItem.doc_number,
        certificate_number: this.item.doc_number,
        certificate_date: this.item.doc_date,
      }
      this.$store.dispatch("catalogs/completeUpdateVehicle", payload).then(() => {
        this.$snackbar({
          text:'Обновлено',
          color:'green',
          top:false
        })
        this.close()
      }).catch(() => {
        this.$snackbar({
          text:'Ошибка',
          color:'red',
          top:false
        })
      })
    },
  }
}
</script>
